@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

/* Body CSS */
body {
    font-family: 'Roboto', sans-serif;
}

body a {
    text-decoration: none !important;
}

body p {
    margin: 0px;
}

/* End of Body CSS */


.blink_me {
    animation: blinker 1s linear infinite;
}

@keyframes blinker {
    30% {
        opacity: 0;
    }
}


/* Header CSS */
.header #product-nav-dropdown {
    color: #fff !important;
}

.header .dropdown-menu {
    background: #67E7D8 !important;
}

/* End of Header CSS */
















/* Common CSS */
body p {
    margin: 0px;
}


body .arrow {
    cursor: pointer;
}

body .wbtn {
    border-radius: 15px 15px 15px 15px !important;
    padding: 5px 20px;
    box-shadow: 3px 6px 24px rgba(0, 0, 0, 0.11);
    border: 1px solid #4C9D80 !important;
    border-color: #4C9D80;
    color: #4C9D80;
    background-color: #fff;
    font-weight: bold;
}

body .pbtn {
    border-radius: 15px 15px 15px 15px !important;
    padding: 5px 20px;
    box-shadow: 3px 6px 24px rgba(0, 0, 0, 0.11);
    border: 1px solid #ffd000 !important;
    border-color: #ffd000;
    color: #ffd000;
    background-color: #fff;
    font-weight: bold;
}

body .wbtn:hover {
    border-color: #4C9D80;
    background-color: #4C9D80;
    color: #fff;
}

body .theme {
    color: #128C7E;
}

body .theme_bg {
    background: #128C7E;
}

body .theme_lite {
    color: #67E7D8;
}

body .theme_bg_lite {
    background: #67E7D8;
}

body .theme_br {
    border: 1px solid #128C7E;
}

body .theme_br_lite {
    border: 1px solid #67E7D8;
}

body .boxShadow {
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.25);
}

body .bRound {
    border-radius: 40px !important;
}

body .emailColor {
    color: #EA4335 !important;
}

body .goldColor {
    color: #e09107 !important;
}

body .theme-light {
    color: #4C9D80 !important;
}

body .heading-text {
    color: #44475b;
}

body .sub-heading-text {
    color: #5e6699;
}

body .desc-text {
    color: #7c7e8c;
}

body .fa-facebook-square {
    color: #1877F2;
}

body .fa-twitter {
    color: #1DA1F2;
}

body .fa-youtube {
    color: #CD201F;
}

body .fa-instagram {
    color: #E4405F;
}

body .fa-linkedin {
    color: #0A66C2;
}

body .fa-whatsapp {
    color: #25D366;
}

body .fa-heart {
    color: red;
}

body .fa-check {
    color: #4C9D80;
}

body .fa-handshake {
    color: #4C9D80;
}

body .fa-copy {
    color: #4C9D80;
}

body .fa-share-alt {
    color: #3db0f3;
}

body .fa-blogger-b {
    color: #fc4f08;
}

body .hide {
    display: none;
}

body .hand {
    cursor: pointer;
}

.linkColour {
    color: #0A66C2;
}

.bg-personal-sec {
    background-color: #e9fff5;
}

/* End of Common CSS */

.countryCodeScroll {
    overflow-y: auto;
    height: 480px !important;
}

.dropdown:hover .dropdown-menu {
    display: block !important;
}

.newHome .clients {
    background: #F8FBF7;
}

.newHome .homeTwo {
    background: rgba(97, 129, 119, .15);
}

.newHome .homeFour {
    background: rgba(97, 129, 119, 0.15);
}

.newHome .homeSeven {
    margin: 45px 0;
    border-radius: 10px;
}

.newHome .homeSeven .forWhatsApp {
    margin: 15px 12px 10px 15px;
    background: #E6F0E4;
    padding: 20px;
    border-radius: 14px;
}

.newHome .homeSeven .forWhatsApp .boxBg {
    box-shadow: 4px 4px 33px #B5CCB1;
    background: #ffffff;
    border-radius: 10px;
}

.newHome .homeRImg {
    height: 385px !important;
}

.footer {
    background: #F8FBF7;
}

.footer i {
    background-color: #000;
    color: #fff;
    padding: 8px;
    border-radius: 50% !important;
    margin-left: 6px;
    font-size: 15px;
}


.productsMenu .dropdown-menu {
    margin-left: -150px !important;
    width: 440px !important;
    font-size: 15px;
    /* margin-top: 0 !important; */
}

.downloadMenu .dropdown-menu {
    margin-left: -50px !important;
    width: 240px !important;
    font-size: 15px;
    /* margin-top: 0 !important; */
}



/* Api CSS */
.testimonials .item img {
    height: 150px;
    border-radius: 15px;
}

/* End of Api CSS */

/* Header CSS */
.header .hamburger_icon {
    padding: 0px 7px 0px 7px !important;
}

.header .site_logo {
    width: 40px !important;
}

.header .g-sign-in {
    width: 100px !important;
}

/* End of Header CSS */

/* Content CSS */
.C_One .wbtn {
    padding: 1px 12px 1px 13px !important;
    font-size: 12px !important;
}

.C_One .for-you-on {
    font-size: 14px;
    margin-top: 3px;
    margin-bottom: -40px;
}

.C_One .blink {
    font-size: 25px;
}

.C_One .left-view .main-text h1 {
    color: #128c7e;
    font-size: 38px;
    margin: 10px 0px 0px 0px;
}

.C_One .left-view .main-desc p {
    font-size: 16px;
    margin: 25px 0px 25px 0px;
    color: #7c7e8c;
}

.C_One .left-view .app-download {
    margin: 0px 0px 25px 0px;
}

.C_One .right-view {
    padding: 0px;
}

.C_One .right-view .row {
    margin-left: -15px !important;
    margin-right: -15px !important;
}

.C_One .right-view .for-personal h5,
.C_One .right-view .for-business h5 {
    font-size: 18px;
    color: #44475b !important;
}

.C_One .right-view .for-personal .items,
.C_One .right-view .for-business .items {
    height: 80px;
    border-radius: 15px;
    padding: 10px 10px 0px 10px;
    box-shadow: 3px 6px 24px rgba(0, 0, 0, 0.11);
}

.C_One .right-view .for-personal .items h6,
.C_One .right-view .for-business .items h6 {
    font-size: 15px;
    color: #44475b;
}

.C_One .right-view .for-personal .items img,
.C_One .right-view .for-business .items img {
    width: 25px !important;
    margin-top: 30px;
}

.C_One .right-view .for-personal .items i,
.C_One .right-view .for-business .items i {
    font-size: 18px;
    margin-top: 30px;
}

.C_02 {
    padding: 0px 10px;
}

.C_02 .box {
    border-radius: 15px;
}

.C_02 .box .client-list img {
    width: 100px;
}

.C_03 {
    padding: 0px 30px;
}

.C_03 .planBtn {
    color: #4C9D80;
}

.C_03 .info p {
    line-height: 30px;
}

.C_04 {
    padding: 0px 30px;
}

.C_04 h3,
.C_04 ul li {
    color: #384A57;
}

.C_04 ul {
    margin-top: 40px;
}

.C_04 ul li {
    line-height: 40px;
}

.C_04 .items {
    border-radius: 15px;
}

.C_04 .items-lottie {
    height: 550px;
    padding: 0px;
}

.C_04 .items-text h3 {
    font-size: 18px;
}

.C_04 .video img {
    cursor: pointer;
    width: 90%;
    box-shadow: 3px 6px 24px rgba(0, 0, 0, 0.11);
    border-radius: 15px;
}

.C_05 {
    padding: 0px 10px;
}

.C_05 .box,
.other_app_info .box {
    border-radius: 15px;
}

.C_05 .info p,
.other_app_info .info p {
    line-height: 30px;
}

.C_05 .anim .items-lottie {
    height: 340px;
    padding: 0px;
}

.other_app_info {
    padding: 0px 30px;
}

/* End of Content CSS */

/* Product Page CSS */
.products .product-icon img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    box-shadow: 3px 6px 24px rgba(0, 0, 0, 0.11);
}

/* End of Product Page CSS */

/* Feature Page CSS */
.f_page .items-lottie {
    width: 500px;
    height: 500px;
}

.features-page .items-lottie {
    width: 500px;
    height: 500px;
}

.features .fbg {
    background-color: #F3F3F3;
}

.features img,
.features-page .f_page_img {
    border-radius: 15px 15px 0px 0px;
    box-shadow: 3px 6px 24px rgba(0, 0, 0, 0.11);
}

.features-page .items-lottie {
    width: 250px;
    height: 250px;
}

/* Bulk Messaging */
.bulk-message .q_left {
    color: #0ad4f8;
}

.bulk-message .q_right {
    color: #128c7e;
}

.bulk-message .fa-whatsapp {
    color: #fff;
}

.bulk-message .bulk-wbtn {
    font-size: 18px;
    border-radius: 40px !important;
    padding: 5px 20px;
    box-shadow: 3px 6px 24px rgba(0, 0, 0, 0.11);
    border: 1px solid #128c7e !important;
    border-color: #128c7e;
    color: #fff;
    background-color: #128c7e;
    font-weight: bold;
}

.bulk-message .bulk-wbtn:hover {
    color: #fff;
}

.modal-info-bulk-msg .items-lottie {
    width: 250px !important;
    height: 250px !important;
}

.modal-info-bulk-msg .fa-whatsapp {
    color: #fff;
}

.modal-info-bulk-msg .bulk-wbtn {
    font-size: 18px;
    border-radius: 40px !important;
    padding: 5px 20px;
    box-shadow: 3px 6px 24px rgba(0, 0, 0, 0.11);
    border: 1px solid #128c7e !important;
    border-color: #128c7e;
    color: #fff;
    background-color: #128c7e;
    font-weight: bold;
}

.modal-info-bulk-msg .bulk-wbtn:hover {
    color: #fff;
}

/* End of Bulk Messaging */

/* Direct Chat CSS */
.direct-chat .fa-whatsapp {
    color: #fff;
}

.direct-chat .direct-wbtn {
    font-size: 18px;
    border-radius: 18px 18px 18px 18px !important;
    padding: 5px 20px;
    border-color: #128c7e;
    color: #fff;
}

.direct-chat .direct-wbtn:hover {
    color: #fff;
}

/* End of Direct Chat CSS */

/* Caption Page CSS */
.caption-status .nav-btn {
    border-radius: 20px;
    box-shadow: 3px 6px 24px rgba(0, 0, 0, 0.11);
    margin-right: 6px;
    color: #44475b;
}

.caption-status .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background-color: #4C9D80 !important;
    color: #fff;
    font-weight: bold;
}

.caption-status i {
    font-size: 20px;
}

.caption-status textarea {
    resize: none;
    pointer-events: none;
    border: none;
}

.caption-status .items-lottie {
    width: 250px;
    height: 250px;
}

.caption-status .list-group-item {
    border-radius: 15px;
    border-color: #7c7e8c;
}

.caption-status .list-group-item hr {
    color: #7c7e8c;
}

/* End of Caption Page CSS */

/* Quick Reply */
.quick-reply .nav-btn {
    border-radius: 20px;
    box-shadow: 3px 6px 24px rgba(0, 0, 0, 0.11);
    margin-right: 6px;
    color: #44475b;
}

.quick-reply .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background-color: #4C9D80 !important;
    color: #fff;
    font-weight: bold;
}

.quick-reply i {
    font-size: 20px;
}

.quick-reply textarea {
    resize: none;
    pointer-events: none;
    border: none;
}

/* End of Quick Reply */
/* End of Feature Page CSS */

/* Premium Page CSS */
.premium .items-lottie {
    width: 200px;
    height: 200px;
}

.premium .premium-plan {
    border: 3px solid rgb(64, 223, 2);
    background-color: rgb(238, 255, 232);
    border-radius: 10px;
    padding: 0px 20px 20px 20px;
}

.premium .essential-plan {
    border: 3px solid rgb(148, 235, 235);
    background-color: rgb(236, 255, 255);
    border-radius: 10px;
    padding: 0px 20px 20px 20px;
}

.premium .basic-plan {
    border: 3px solid rgb(196, 196, 196);
    background-color: rgb(247, 242, 242);
    border-radius: 10px;
    padding: 0px 20px 60px 20px;
}

.premium .plan_name_line {
    width: 30px !important;
    height: 3px !important;
    border-radius: 20px !important;
}

.premium .buyNowBtn {
    background-color: #25D366 !important;
    color: #fff;
}

.premium .offer-text {
    background-color: red;
    width: 250px;
    border-radius: 20px;
    margin: -3px 0px 0px -25px;
    padding: 3px 10px;
    color: #fff;
    font-size: 14px;
}

.premium .offer-text2 {
    background-color: red;
    width: 250px;
    border-radius: 20px;
    margin: -3px 0px 0px -15px;
    padding: 3px 10px;
    color: #fff;
    font-size: 14px;
}

.premium .plan .only {
    font-size: 15px;
}

.premium .customer-support h2,
.premium .satisfaction h2,
.premium .refund h2,
.premium .payment-help h2 {
    font-size: 20px;
}

.premium .customer-support p {
    font-size: 12px;
}

.premium .chat-with-us p {
    font-size: 12px;
}

.premium .recBg {
    background-color: #fff;
    border: 2px solid #128c7e;
    margin: -3px -24px 0px 0px;
}

.premium .refund .modal .modal-dialog {
    max-width: 650px !important;
}

.premium .refund .modal .modal-body {
    font-size: 10px;
    text-align: justify;
}

/* End of Premium Page CSS */

/* Reseller Program */
.reseller .items-lottie {
    width: 200px;
    height: 200px;
}

/* End of Reseller Program */

/* Team Page CSS */
.team .profile-img img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background-color: #9695959d;
    padding: 3px;
}

.team .profile-social-link {
    margin-top: -7px;
}

.team .bg_image img {
    width: 100%;
    height: auto;
    background-position: center;
    background-repeat: no-repeat;
}

.team .t_heading {
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
    color: white;
    font-weight: bold;
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 80%;
    padding: 20px;
    text-align: center;
}

.team .ceo_image {
    position: absolute;
    top: 148%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 80%;
    padding: 20px;
    text-align: center;
}

.team .team_mates {
    margin-top: 250px !important;
}

/* End of Team Page CSS */

/* Gallery CSS */
.gallery iframe {
    border-radius: 15px;
}

.images-gallery img {
    width: 300px !important;
    height: auto;
    margin: 10px;
    border-radius: 5px;
    transition: all 1s;
}

.images-gallery img:hover {
    transform: scale(1.07);
}

.images-gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

/* End of Gallery CSS */

/* Footer CSS */
.footer-top {
    background-color: #44475b;
    font-size: 20px !important;
}

.footer-top .c_loc {
    font-size: 14px;
}

.footer-btm {
    background-color: #5e6699;
    font-size: 14px;
}

.footer-top .download .download-beta {
    font-size: 15px;
}

.footer-top .download .download-beta img {
    border-radius: 7px;
}

/* End of Footer CSS */

/* About CSS */
.about h1 {
    font-size: 60px !important;
}

.about h4 {
    font-size: 45px !important;
}

.about h5 {
    font-size: 30px !important;
}

.about .tcolor {
    color: #128c7e;
    ;
}

/* End of About CSS */

/* Upgrade Info */
.upgrade img {
    width: 300px;
    height: auto;
}

.upgrade .items-lottie {
    width: auto;
    height: 330px;
}

.upgrade .bg_01,
.upgrade .bg_06,
.upgrade .bg_11 {
    background-color: #FBF9EF;
}

.upgrade .bg_02,
.upgrade .bg_07,
.upgrade .bg_12 {
    background-color: #E6F6F0;
}

.upgrade .bg_03,
.upgrade .bg_08,
.upgrade .bg_13 {
    background-color: #F0F4F9;
}

.upgrade .bg_04,
.upgrade .bg_09,
.upgrade .bg_14 {
    background-color: #D8E8EA;
}

.upgrade .bg_05,
.upgrade .bg_10,
.upgrade .bg_15 {
    background-color: #FBF9EF;
}

/* End of Upgrade Info */

/* Privacy Policy */
.privacy-policy {
    color: #656a6d;
}

/* End of Privacy Policy */

/* FAQ CSS */
.faq .items-lottie {
    width: 300px;
    height: 300px;
}

/* End of FAQ CSS */

/* Client Page CSS */
.clients .items-lottie {
    width: 300px;
    height: 300px;
}

.clients .client-list img {
    width: 150px;
    height: auto;
}

.clients .clientTestimonials img {
    border-radius: 15px;
    width: 600px;
    height: 300px;
}

.clients .clientTestimonials .fa-chevron-left,
.clients .clientTestimonials .fa-chevron-right {
    background-color: #44475b;
    padding: 15px;
    border-radius: 50%;
    color: #fff;
}

/* End of Client Page CSS */

/* More Tools CSS */
.more-tools .tools-box {
    border-radius: 10px;
}

/* End of More Tools CSS */

/* Media Queries */
@media (max-width: 767px) {
    .team .t_heading {
        top: 24%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        width: 80%;
        padding: 12px;
    }

    .team .ceo_image {
        top: 50% !important;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        width: 80%;
        padding: 20px;
        text-align: center;
    }

    .team .ceo_image .profile-img img {
        width: 120px !important;
        height: 120px !important;
    }

    .team .team_mates {
        margin-top: 200px !important;
    }


}

@media (min-width: 360px) and (max-width: 767.98px) {
    .hide-in-small {
        display: none !important;
    }

    .header .hamburger_icon {
        display: block;
    }

    .header .small-scr-blog-premium {
        display: block;
    }

    .header .big-scr-nav {
        display: none !important;
    }

    .header .site_logo {
        width: 30px !important;
    }

    .C_04 {
        padding: 0px 5px;
    }

    .C_04 h3 {
        font-size: 20px;
    }

    .C_04 ul {
        margin-top: 0px;
    }

    .video iframe {
        width: 320px !important;
        height: 180px;
    }

    .C_04 .items-lottie {
        width: auto;
        height: auto;
        padding: 0px;
    }

    .footer-top {
        padding: 20px !important;
    }

    .footer-btm {
        padding: 20px !important;
    }

    .team .t_heading {
        top: 24%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        width: 80%;
        padding: 12px;
    }

    .team .ceo_image {
        top: 50% !important;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        width: 80%;
        padding: 20px;
        text-align: center;
    }

    .team .ceo_image .profile-img img {
        width: 120px !important;
        height: 120px !important;
    }

    .team .team_mates {
        margin-top: 200px !important;
    }

    .clients .clientTestimonials img {
        border-radius: 15px;
        width: auto;
        height: auto;
    }







    .productsMenu .dropdown-menu {
        margin-left: 0px !important;
        width: 300px !important;
        font-size: 15px !important;
    }

    .downloadMenu .dropdown-menu {
        margin-left: 0px !important;
        width: 240px !important;
        font-size: 15px;
    }

    .onSmallBtn {
        margin-top: 20px !important;
    }

    .newHome .hOne .tx1 {
        font-size: 12px !important;
        margin-top: -30px !important;
    }

    .newHome .hOne .tx2 {
        font-size: 16px !important;
        margin-top: -4px !important;
    }

    .newHome .hOne img {
        margin-top: 110px !important;
    }

    .newHome .clients h4 {
        margin-top: 10px !important;
    }

    .hTwo .pTwo {
        padding: 0px !important;
    }

    .newHome .hTwo img,
    .newHome .hThree img,
    .newHome .hFour img,
    .newHome .hFive img,
    .newHome .hSix img {
        margin-top: 20px !important;
        height: auto !important;
    }

    .newHome .hThree .pThree {
        padding: 8px !important;
    }

    .newHome .hFour .pFour {
        padding: 0px !important;
    }

    .newHome .hFive .pFive {
        padding: 8px !important;
    }

    .newHome .hSix .pSix {
        padding: 0px !important;
    }

    .newHome .homeSeven {
        margin: 10px 0px 10px 0px !important;
        padding: 0px !important;
    }

    .newHome .hSeven .tx1 {
        margin: 20px 0px 10px 0px !important;
        padding: 0px !important;
    }

    .newHome .hSeven .forWhatsApp {
        margin: 6px 100px 0px 6px !important;
        padding: 0px 0px 6px 0px !important;
        width: 324px !important;
    }

    .newHome .hSeven .forWhatsApp h5 {
        margin: 0px 24px 0px 18px !important;
        padding: 0px !important;
        font-size: 15px !important;

    }

    .newHome .hSeven .forWhatsApp .col {
        padding: 0px !important;
        margin: 0px !important;
    }

    .newHome .hSeven {
        margin: 10px 55px 10px 0px !important;
        padding: 0px !important;
    }

    .newHome .hSeven .hSevenB .image {
        margin-top: -70px !important;
        margin-bottom: -10px !important;
    }

    .newHome .hSeven .forWhatsApp .boxBg {
        margin: 3px !important;
        padding: 6px 0px 6px 15px !important;
    }

    .newHome .hSeven .forWhatsApp .boxBg h5 {
        font-size: 15px !important;
        margin: 0px !important;
        padding: 0px !important;
    }

    .newHome .headingTx {
        font-size: 24px !important;
    }

    .newHome .descTx {
        font-size: 12px !important;
    }

    .footer .in {
        padding: 0px 0px 0px 10px !important;
        margin: 0px !important;
    }

    .footer .dImg {
        width: 140px
    }

    .footer .social i {
        background-color: #000;
        color: #fff;
        padding: 6px !important;
        border-radius: 50% !important;
        margin-left: 6px;
        font-size: 12px;
    }

    .footer .social .fb {
        padding: 6px 8px 6px 8px !important;
        border-radius: 50% !important;
    }
}

@media (min-width: 768px) and (max-width: 799px) {
    .header .hamburger_icon {
        display: block;
    }

    .header .small-scr-blog-premium {
        display: block;
    }

    .header .big-scr-nav {
        display: none !important;
    }

    .team .t_heading {
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        width: 80%;
        padding: 12px;
    }

    .team .ceo_image {
        top: 83% !important;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        width: 80%;
        padding: 20px;
        text-align: center;
    }

    .team .ceo_image .profile-img img {
        width: 120px !important;
        height: 120px !important;
    }

    .team .team_mates {
        margin-top: 200px !important;
    }
}

@media (min-width: 800px) and (max-width: 979px) {
    .header .hamburger_icon {
        display: block;
    }

    .header .small-scr-blog-premium {
        display: block;
    }

    .header .big-scr-nav {
        display: none !important;
    }

    .team .t_heading {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        width: 80%;
        padding: 12px;
    }

    .team .ceo_image {
        top: 100% !important;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        width: 80%;
        padding: 20px;
        text-align: center;
    }

    .team .ceo_image .profile-img img {
        width: 150px !important;
        height: 150px !important;
    }

    .team .team_mates {
        margin-top: 200px !important;
    }


}

@media (min-width: 980px) and (max-width: 1199.98px) {
    .team .t_heading {
        top: 54%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        width: 80%;
        padding: 12px;
    }

    .team .ceo_image {
        top: 102% !important;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        width: 80%;
        padding: 20px;
        text-align: center;
    }

    .team .ceo_image .profile-img img {
        width: 180px !important;
        height: 180px !important;
    }

    .team .team_mates {
        margin-top: 200px !important;
    }
}

@media (min-width: 1200px) and (max-width: 1400px) {
    .team .t_heading {
        top: 70%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        width: 80%;
        padding: 12px;
    }

    .team .ceo_image {
        top: 148% !important;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        width: 80%;
        padding: 20px;
        text-align: center;
    }

    .team .ceo_image .profile-img img {
        width: 200px !important;
        height: 200px !important;
    }

    .team .team_mates {
        margin-top: 240px !important;
    }
}

/* End of Media Queries */

/* Start of  WTB Business Growth */

@media only screen and (max-width: 1024px) {

    .BGpadding {
        padding: 0 !important;
    }

    .BGmargin {
        margin: 0 !important;
    }

    .BgfontSize {
        font-size: 32px !important;
    }

    .BgMinHeight {
        min-height: 300px !important;
    }

    .disBlock {
        display: block !important;
    }

    .disnone {
        display: none !important;
    }
}

.carousel-dark .carousel-indicators [data-bs-target] {
    display: none !important;
}

.carousel-control-prev {
    display: none !important;
}

@media (min-width: 360px) and (max-width: 767.98px) {

    .testimonials,
    .testimonials .mainSideImg {
        height: 150px !important;
    }

    .testimonials .logo {
        height: 30px !important;
    }

    .testimonials .tesBody {
        padding: 10px !important;
    }

    .testimonials .tesBody .tes_info {
        margin-top: 6px !important;
    }

    .testimonials p {
        font-size: 8px !important;
    }

    .testimonials h3 {
        margin-top: 0px !important;
        font-size: 10px !important;
    }

    .testimonials h6 {
        margin-top: -10px !important;
        font-size: 10px !important;
    }

    .testimonials .lineheight {
        line-height: 23px !important;
    }
}

@media (min-width: 360px) and (max-width: 767.98px) {

    .justify {
        justify-content: center !important;
    }

    .Blogs .margin {
        margin-top: 8px !important;
    }

    .Homemobmargin {
        margin-top: 40px !important;
    }

    .Wthomedisplay {
        display: grid !important;
    }

    .Wthomepadding {
        padding: 24px !important;
    }

    .Wthomepaddingtop {
        padding-top: 24px !important;
    }

    .Wthomepading {
        padding: 24px 0 !important;
    }

    .WtHomepading {
        padding: 0 !important;
    }
}

@media (min-width: 769px) and (max-width: 1023.98px) {
    .Hometwomargin {
        margin-top: 40px !important;
    }
}

.WtHomeHover:hover {
    color: #00A82D !important;
}

